<template>
  <div class="pdfViewer">
    <div class="row">
      <div class="col-3">
        <v-icon
          @click="$emit('close')"
          name="times-circle"
          style="cursor:pointer;"
        />
      </div>
      <div class="col-8 text-right">
        <b-button
          v-if="pdfPage > 1"
          variant="outline-secondary" class="ml-2" size="sm"
          @click='pdfPage --'>
          <v-icon name="arrow-left"/>
        </b-button>
        <b-button
          v-if="pdfPage < pdfPageCount"
          variant="outline-secondary" class="ml-2" size="sm"
          @click='pdfPage ++'>
          <v-icon name="arrow-right"/>
        </b-button>
        <b-button
          variant="outline-secondary" class="ml-2" size="sm"
          @click='pdfRotate -= 90'>
          <v-icon name="undo"/>
        </b-button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 text-center">
        <pdf
          ref="pdfDocumentViewer" style="border: 1px solid black"
          :src="pdfSrc"
          :rotate="pdfRotate"
          :page="pdfPage"
          @num-pages="pdfPageCount = $event">
        </pdf>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'pdfViewer',
  props: {
    pdfSrc: {
      type: String
    }
  },
  components: {
    pdf,
  },
  data: function () {
    return {
      pdfRotate: 0,
      pdfPage: 1,
      pdfPageCount: 0,
    }
  }
}
</script>
