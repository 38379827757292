<template>
  <div class="conseillerRemboursementDocumentsProfile">

    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
      <div class="text-center">
        <p>Une erreur s'est produite lors du chargement des informations de l'remboursement,
          veuillez réactualiser la page ou nous contacter directement.
        </p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- Document Uploaded -->
    <b-modal ref="remboursementDocumentUploadedModal" size="lg" id="remboursementDocumentUploadedModal" hide-footer title="Votre document a bien été envoyé et enregistré">
      <div class="text-center">
        <p><strong>Votre document a bien été envoyé.</strong> <br>
        </p>
        <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('remboursementDocumentUploadedModal')">Fermer</b-button>
      </div>
    </b-modal>


    <!-- Create Document -->
    <b-modal ref="createRemboursementDocumentModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Charger votre document </strong>
      </template>
      <div>
        <b-form>
          <b-form-group>
              <b-form-file
                class="text-truncate"
                browse-text='Parcourir'
                required
                v-model="createRemboursementDocumentFile"
                id="create-document-file-input"
                placeholder="Choisissez un fichier"
                drop-placeholder="Déposez un fichier ici"
                @input="$v.createRemboursementDocumentFile.$touch()"
                :state="$v.createRemboursementDocumentFile.$dirty ? !$v.createRemboursementDocumentFile.$error : null"
              ></b-form-file>
              <div class="form-error" v-if="!$v.createRemboursementDocumentFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</div>
              <div class="form-error" v-if="!$v.createRemboursementDocumentFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg ou png</div>
              <div class="form-error" v-if="!$v.createRemboursementDocumentFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
              <div class="form-error" v-if="!$v.createRemboursementDocumentFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
          </b-form-group>
          <b-button
            pill block variant="outline-dark" class="mt-2"
            @click='createRemboursementDocument'
            :disabled="createRemboursementDocumentInProcess || $v.createRemboursementDocumentFile.$invalid  ">
              Charger votre document
          </b-button>
        </b-form>
      </div>
    </b-modal>


    <b-modal ref="addPeriodeEmploiModal" id ="addPeriodeEmploiModal" hide-footer title="Ajouter une période d'emploi" size="xl">
      <p><strong>Ajouter une période d'emploi</strong></p>
      <div class="row">
        <div class="col-12 col-md-5 text-center">
          <b-form-group
            id="modify-periode-emplois-start-date"
            label-cols-sm="4"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
            label="Date de début"
            label-for="modify-periode-emplois-start-date"
          >
          <b-form-input
            id="modify-periode-emplois-start-date"
            v-model="newPeriodeEmploiStartDate"
            type="date"
            placeholder="DD/MM/YYYY"
            autocomplete="off"
          ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-5 text-center">
          <b-form-group
            id="modify-periode-emplois-end-date"
            label-cols-sm="4"
            label-cols-lg="4"
            content-cols-sm
            content-cols-lg="8"
            label="Date de fin"
            label-for="periode-emplois-end-date"
          >
          <b-form-input
            id="modify-periode-emplois-end-date"
            v-model="newPeriodeEmploiEndDate"
            type="date"
            placeholder="DD/MM/YYYY"
            autocomplete="off"
          ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-2 text-center">
          <b-button
            variant="outline-info"
            @click="addPeriodeEmploi"
            :disabled="$v.newPeriodeEmploiStartDate.$invalid || $v.newPeriodeEmploiEndDate.$invalid"
          >
            Ajouter
          </b-button>
        </div>
      </div>
    </b-modal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#BB3634">
    </loading>

    <h2>Documents liés à la demande de remboursement</h2>

    <!-- //////////////////////// TELEACTUALISATION //////////////// -->
    <div class="row mt-3">
      <div class="col-12">
        <h3 style=" margin-bottom: 0px;"> Téléactualisation de France Travail pour le mois concerné</h3>
        <em class="text-secondary">
          Les justificatifs de téléactualisation de France Travail pour le mois de
          {{monthRender(remboursement.month)}} {{remboursement.year}},
          indiquant le nombre d’heures de travail ayant nécessité la garde.
        </em><br>

      </div>
    </div>

    <div v-if="avisPoleEmploiDocs.length === 0">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-3 text-center">
          <em>Aucun document encore chargé </em>
        </div>
      </div>
    </div>

    <div v-else>
      <b-table
        responsive class="align-middle text-center mt-4 cursor-table"
        headVariant="light"
        :fields="teleactualisationDocumentsRenderFields"
        :items="avisPoleEmploiDocs"
        >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template v-slot:cell(file_name)="data">
          Téleactualisation<br>
          <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
        </template>

        <template v-slot:cell(state)="data">
          <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
          <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
          <strong v-else class="text-secondary">A valider</strong>
        </template>
        <template v-slot:cell(nb_hours)="data">
          <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
            <small>{{data.item.nb_hours}} heures </small><br>
            <small>{{data.item.ccn}}</small>
          </div>

          <div v-else>
            <b-input-group>
              <b-form-input
                size="sm" v-model="data.item.nb_hours"
                type="number"
                min="0" max="1000000" step="1"
                placeholder="Nombre d'heures correspondants (facultatif)"
                @change="setNbHoursCCNRemboursementDocument(data.item.id, data.item.nb_hours, data.item.ccn)"
              >
              </b-form-input>
            </b-input-group>
            <b-input-group>
              <b-form-select
                size="sm"
                v-model="data.item.ccn"
                :options='ccnOptionsList'
                @change="setNbHoursCCNRemboursementDocument(data.item.id, data.item.nb_hours, data.item.ccn)"
              >
              </b-form-select>
            </b-input-group>
          </div>
        </template>
        <template v-slot:cell(periode_emplois)="data">
          <div
            class="mt-1"
            v-for="(periode_emploi, index) in data.item.periode_emplois" :key="index"
          >
            <small
              class="mt-2 p-1"
              style="border-radius: 10px; background-color: #D3D3D3; white-space:nowrap;"
            >
              {{$dayjs(periode_emploi.start_date).format('DD/MM/YYYY')}} / {{$dayjs(periode_emploi.end_date).format('DD/MM/YYYY')}}
              <button
                @click="deletePeriodeEmploi(data.item, index, periode_emploi)"
                style="background-color: transparent !important; border: 0 !important;">
                <v-icon name="times"/>
              </button>
            </small>
            <br>
          </div>
          <b-button
            class="mt-2"
            variant="outline-success"
            size="sm"
            pill
            @click="showAddPeriodeEmploiModal(data.item)"
          >
            Ajouter
          </b-button>
        </template>
        <template v-slot:cell(actions)="data">
          <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
            <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
          </div>
          <div v-else>
            <b-button
              v-b-popover.hover.top="'Visualiser en pdf'"
              variant="outline-danger" class="ml-2" size="sm"
              @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "TELEACTUALISATION")'>
              <v-icon name="file-pdf"/>
            </b-button>
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'VALIDATED'"
              variant="outline-success" class="ml-2" size="sm"
              :disabled="validateRemboursementDocumentInProcess"
              @click='conseillerValidateRemboursementDocument(data.item.id)'>
              <v-icon name="check-circle"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'REFUSED'"
              variant="outline-danger" class="ml-2" size="sm"
              :disabled="refuseRemboursementDocumentInProcess"
              @click='conseillerRefuseRemboursementDocument(data.item.id)'>
              <v-icon name="times-circle"/>
            </b-button>
          </div>
        </template>
      </b-table>
      <pdfViewer
        v-if="pdfViewerType === 'TELEACTUALISATION'"
        :pdfSrc="pdfSrc"
        @close="closePdfViewer"
      />
    </div>

    <div class="row mt-2">
      <div class="col-sm-12 text-right">
        <b-button
          variant="outline-info" size="sm" pill
          @click="showCreateRemboursementDocumentModal('TELEACTUALISATION')">
            <v-icon name="plus" /> Ajouter un fichier
        </b-button>
      </div>
    </div>

    <div v-if="teleActualisationChevauchageDate.length > 0">
      <p>
        <strong class="text-danger">Attention, des périodes d'emploi se chevauchent</strong>
      </p>
      <div
        v-for="(chevauchage_periode_emploi, index) in teleActualisationChevauchageDate" :key="index"
        class="mt-2"
      >
        <small
          class="p-1 mr-2"
          style="border-radius: 10px; background-color: #D3D3D3; white-space:nowrap;"
        >
          {{$dayjs(chevauchage_periode_emploi[0].start_date).format('DD/MM/YYYY')}} → {{$dayjs(chevauchage_periode_emploi[0].end_date).format('DD/MM/YYYY')}}
        </small>
        et
        <small
          class="p-1 ml-2"
          style="border-radius: 10px; background-color: #D3D3D3; white-space:nowrap;"
        >
          {{$dayjs(chevauchage_periode_emploi[1].start_date).format('DD/MM/YYYY')}} → {{$dayjs(chevauchage_periode_emploi[1].end_date).format('DD/MM/YYYY')}}
        </small>
      </div>
    </div>

    <hr>


    <!-- //////////////////////// FICHES DE PAIES //////////////// -->
    <div class="row mt-3">
      <div class="col-12">
        <h3 style=" margin-bottom: 0px;"> Bulletin(s) de salaire / Contrat(s) de travail <u>(TEMPORAIRE)</u></h3>
        <em class="text-secondary">
          Le(s) bulletin(s) de salaire et/ou le(s) contrat(s) de
          travail en CDD ou CDDU dans une entreprise du spectacle justifiant le recours nécessaire
          à la garde d’enfant(s) et indiquant le nombre d’heures de travail ayant nécessité la garde.
        </em><br>

      </div>
    </div>

    <div v-if="paieDocs.length === 0">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-3 text-center">
          <em>Aucun document encore chargé </em>
        </div>
      </div>
    </div>

    <div v-else>
      <b-table
        responsive class="align-middle text-center mt-4 cursor-table"
        headVariant="light"
        :fields="documentsRenderFields"
        :items="paieDocs"
        >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template v-slot:cell(file_name)="data">
          Bulletin<br>
          <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
        </template>

        <template v-slot:cell(state)="data">
          <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
          <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
          <strong v-else class="text-secondary">A valider</strong>
        </template>

        <template v-slot:cell(actions)="data">
          <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
            <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
          </div>
          <div v-else>
            <b-button
              v-b-popover.hover.top="'Visualiser en pdf'"
              variant="outline-danger" class="ml-2" size="sm"
              @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "PAIE")'>
              <v-icon name="file-pdf"/>
            </b-button>
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'VALIDATED'"
              variant="outline-success" class="ml-2" size="sm"
              :disabled="validateRemboursementDocumentInProcess"
              @click='conseillerValidateRemboursementDocument(data.item.id)'>
              <v-icon name="check-circle"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'REFUSED'"
              variant="outline-danger" class="ml-2" size="sm"
              :disabled="refuseRemboursementDocumentInProcess"
              @click='conseillerRefuseRemboursementDocument(data.item.id)'>
              <v-icon name="times-circle"/>
            </b-button>
          </div>
        </template>
      </b-table>
      <pdfViewer
        v-if="pdfViewerType === 'PAIE'"
        :pdfSrc="pdfSrc"
        @close="closePdfViewer"
      />
    </div>

    <div class="row mt-2">
      <div class="col-sm-12 text-right">
        <b-button
          variant="outline-info" size="sm" pill
          @click="showCreateRemboursementDocumentModal('PAIE')">
            <v-icon name="plus" /> Ajouter un fichier
        </b-button>
      </div>
    </div>

    <hr>

    <!-- //////////////////////// ATTESTATION DE PAIEMENT DE LA CAF //////////////// -->
    <div v-if="remboursement.is_creche || remboursement.is_gad_structure || remboursement.is_pair">
      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Attestation de paiement de la CAF</h3>
          <em class="text-secondary">
            Il s'agit de l'attestation de paiement de la CAF sur la période concernée
          </em><br>

        </div>
      </div>

      <div v-if="cafPaieDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="cafPaieDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Attestation Caf<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "CAFPAIE")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'CAFPAIE'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('CAFPAIE')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>
    </div>




    <!-- CRECHE -->
    <div v-if="remboursement.is_creche">
      <h3 class="mt-4 text-info"> Crèche ou périscolaire - Montant : {{ montantCalculatedPerModeGarde['CRECHE']}} €</h3>

      <!-- //// Garde caractéristiques //// -->
      <p>
        <strong>Attention</strong>, si un des enfants est concerné par le mode de garde "crèche" ET par le mode
        de garde "périscolaire", veuillez cliquer sur vérifier qu'il y a bien deux lignes pour cet enfant et ce mode de garde.
      </p>


      <!-- On liste les garde_caracteristiques -->
      <div
        v-for="garde_caracteristique in remboursement.garde_caracteristiques.filter(item => item.type == 'CRECHE')"
        :key="garde_caracteristique.id"
        class="mt-2"
      >
        <strong> Informations concernant <u>{{garde_caracteristique.child_data.first_name}} {{garde_caracteristique.child_data.last_name}}</u> : </strong>
        <gardeCaracteristique
          type="CRECHE"
          :remboursement-id="remboursement.id"
          :child="garde_caracteristique.child_data"
          :garde-caracteristique="garde_caracteristique"
          :isExistingGarde="true"
          @modify-garde-information="modifyGardeCaracteristiques($event)"
          :disabled="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'"
        />
      </div>

      <div
        v-for="child in filterChildWithoutGardeCaracteristiques('CRECHE')"
        :key="child.id"
        class="mt-2"
      >
        <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
        <gardeCaracteristique
          type="CRECHE"
          :remboursement-id="remboursement.id"
          :child="child"
          :isExistingGarde="false"
          @create-garde-information="createGardeCaracteristiques($event)"
          :disabled="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'"
        />
      </div>

      <div class="mt-2">
        <div
          class="row"
        >
          <div class="col-12 col-md">
            <b-form-group
              id="add-creche-child-garde-car-group"
              label-for="add-creche-child-garde-car"
            >
              <b-form-select
                v-model="newChildGardeCaracteristiqueCreche"
                id="add-creche-child-garde-car"
                :disabled="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'"
                :options='childrenSelect'>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-12 col-md-3 text-center align-self-end">
            <b-button
              @click="addNewGardeCaracteristiquesLine('CRECHE')"
              :disabled="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'"
              variant="outline-success"
              size="sm"
              pill
              block
              style="margin-bottom: 1rem;"
            >
              Ajouter une ligne d'informations
            </b-button>
          </div>
        </div>
      </div>

      <div
        v-if="childGardeCaractertiqueAdd['CRECHE']" class="mt-2"
      >

        <strong> Informations concernant <u>{{childGardeCaractertiqueAdd['CRECHE'].first_name}} {{childGardeCaractertiqueAdd['CRECHE'].last_name}}</u> : </strong>
        <gardeCaracteristique
          type="CRECHE"
          :remboursement-id="remboursement.id"
          :child="childGardeCaractertiqueAdd['CRECHE']"
          :isExistingGarde="false"
          @create-garde-information="createGardeCaracteristiques($event)"
          :disabled="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'"
        />

      </div>



      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Facture mensuelle</h3>
          <em class="text-secondary">
            Il s'agit de la facture liée à la garde mentionnant le taux horaire ou le nombre d'heures de garde réglées
          </em><br>

        </div>
      </div>


      <div v-if="factMensDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="factMensDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Facture<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "FACTMENS")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>

        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'FACTMENS'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>

      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('FACTMENS')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>
    </div>



    <!-- ///////////////////////// GAD PAJEMPLOI /////////////////// -->
    <div v-if="remboursement.is_gad_pajemploi">
      <h3 class="mt-4 text-info"> Garde à domicile/Assistante maternelle via PAJEMPLOI - Montant : {{ montantCalculatedPerModeGarde['GAD_PAJEMPLOI']}} €</h3>

      <!-- //// Garde caractéristiques //// -->
      <div
        v-for="child in remboursement.children"
        :key="child.id"
        class="mt-2"
      >
        <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
        <gardeCaracteristique
          type="GAD_PAJEMPLOI"
          :remboursement-id="remboursement.id"
          :child="child"
          :disabled="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'"
          :garde-caracteristique="filterGardeCaracteristiques('GAD_PAJEMPLOI', child.id)"
          :isExistingGarde="filterGardeCaracteristiques('GAD_PAJEMPLOI', child.id) ? true : false"
          @create-garde-information="createGardeCaracteristiques($event)"
          @modify-garde-information="modifyGardeCaracteristiques($event)"
        />
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Fiches de paie de l'employé(e)</h3>
          <em class="text-secondary">
            Il s'agit des fiches de paie de l'employé(e) sur la période concerné(e)
          </em><br>

        </div>
      </div>


      <div v-if="fichePaieDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="fichePaieDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Fiche de paie<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "FICHEPAIE")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'FICHEPAIE'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>

      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('FICHEPAIE')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Certificat ou relevé mensuel de PAJEMPLOI</h3>
          <em class="text-secondary">
            Il s'agit du certificat d'enregistrement ou du relevé mensuel de PAJEMPLOI du mois concerné
          </em><br>

        </div>
      </div>

      <div v-if="certPajDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="certPajDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Certificat<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "CERTPAJ")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'CERTPAJ'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('CERTPAJ')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>
    </div>





    <!-- ///////////////////////// GAD CESU /////////////////// -->
    <div v-if="remboursement.is_gad_cesu">
      <h3 class="mt-4 text-info"> Garde à domicile via CESU - Montant : {{ montantCalculatedPerModeGarde['GAD_CESU']}} €</h3>

      <!-- //// Garde caractéristiques //// -->
      <div
        v-for="child in remboursement.children"
        :key="child.id"
        class="mt-2"
      >
        <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
        <gardeCaracteristique
          type="GAD_CESU"
          :remboursement-id="remboursement.id"
          :child="child"
          :disabled="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'"
          :garde-caracteristique="filterGardeCaracteristiques('GAD_CESU', child.id)"
          :isExistingGarde="filterGardeCaracteristiques('GAD_CESU', child.id) ? true : false"
          @create-garde-information="createGardeCaracteristiques($event)"
          @modify-garde-information="modifyGardeCaracteristiques($event)"
        />
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Bulletin de salarie de l'employé(e)</h3>
          <em class="text-secondary">
            Il s'agit du bulletin de salaire de l'employé(e) sur le mois concerné
          </em><br>

        </div>
      </div>

      <div v-if="paieEmployeDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="paieEmployeDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Bulletin de salaire<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "PAIEEMPLOYE")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'PAIEEMPLOYE'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>

      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('PAIEEMPLOYE')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Bordereau de cotisations sociales</h3>
          <em class="text-secondary">
            Il s'agit de votre bordereau de cotisations sociales sur le mois concerné
          </em><br>

        </div>
      </div>

      <div v-if="bordDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="bordDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Bordereau<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "BORD")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'BORD'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('BORD')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>
    </div>





    <!-- ///////////////////////// GAD STRUCT /////////////////// -->
    <div v-if="remboursement.is_gad_structure">
      <h3 class="mt-4 text-info"> Garde à domicile via une structure de garde d'enfants / Microcrèche - Montant : {{ montantCalculatedPerModeGarde['GAD_STRUCTURE']}} €</h3>

      <!-- //// Garde caractéristiques //// -->
      <div
        v-for="child in remboursement.children"
        :key="child.id"
        class="mt-2"
      >
        <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
        <gardeCaracteristique
          type="GAD_STRUCTURE"
          :remboursement-id="remboursement.id"
          :child="child"
          :disabled="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'"
          :garde-caracteristique="filterGardeCaracteristiques('GAD_STRUCTURE', child.id)"
          :isExistingGarde="filterGardeCaracteristiques('GAD_STRUCTURE', child.id) ? true : false"
          @create-garde-information="createGardeCaracteristiques($event)"
          @modify-garde-information="modifyGardeCaracteristiques($event)"
        />
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Facture réglée de garde</h3>
          <em class="text-secondary">
            Il s'agit de la facture réglée émise par la strucutre sur le mois concenné
          </em><br>

        </div>
      </div>

      <div v-if="structFactDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="structFactDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Facture<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "STRUCTFACT")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'STRUCTFACT'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('STRUCTFACT')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>
    </div>






    <!-- ///////////////////////// PAIR /////////////////// -->
    <div v-if="remboursement.is_pair">
      <h3 class="mt-4 text-info"> Jeune fille au pair - Montant : {{ montantCalculatedPerModeGarde['PAIR']}} €</h3>

      <!-- //// Garde caractéristiques //// -->
      <div
        v-for="child in remboursement.children"
        :key="child.id"
        class="mt-2"
      >
        <strong> Informations concernant <u>{{child.first_name}} {{child.last_name}}</u> : </strong>
        <gardeCaracteristique
          type="PAIR"
          :remboursement-id="remboursement.id"
          :child="child"
          :disabled="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'"
          :garde-caracteristique="filterGardeCaracteristiques('PAIR', child.id)"
          :isExistingGarde="filterGardeCaracteristiques('PAIR', child.id) ? true : false"
          @create-garde-information="createGardeCaracteristiques($event)"
          @modify-garde-information="modifyGardeCaracteristiques($event)"
        />
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Contrat de travail</h3>
          <em class="text-secondary">
            Il s'agit du contrat de travail de la jeune fille au pair
          </em><br>

        </div>
      </div>


      <div v-if="contratDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="contratDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Contrat<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "CONTRAT")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'CONTRAT'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('CONTRAT')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Déclaration trimestrielle de l'URSSAF</h3>
          <em class="text-secondary">
            Il s'agit de la déclaration trimestrielle de l'URSSAF associée et comprenant le mois concerné
          </em><br>

        </div>
      </div>

      <div v-if="decUrssafDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="decUrssafDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Déclaration<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "DECURSSAF")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'DECURSSAF'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('DECURSSAF')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>
    </div>


    <!-- //////////////////////// OTHER //////////////// -->
    <div class="row mt-3">
      <div class="col-12">
        <h3 style=" margin-bottom: 0px;"> Autres justificatifs</h3>
        <em class="text-secondary">
          Vous pouvez ajouter d'autres documents ou justificatifs à votre produit.
        </em><br>
      </div>
    </div>


    <div v-if="otherDocs.length === 0">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-3 text-center">
          <em>Aucun document encore chargé </em>
        </div>
      </div>
    </div>

    <div v-else>
      <b-table
        responsive class="align-middle text-center mt-4 cursor-table"
        headVariant="light"
        :fields="documentsRenderFields"
        :items="otherDocs"
        >
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template v-slot:cell(file_name)="data">
          Autre<br>
          <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
        </template>

        <template v-slot:cell(state)="data">
          <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
          <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
          <strong v-else class="text-secondary">A valider</strong>
        </template>

        <template v-slot:cell(actions)="data">
          <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
            <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
          </div>
          <div v-else>
            <b-button
              v-b-popover.hover.top="'Visualiser en pdf'"
              variant="outline-danger" class="ml-2" size="sm"
              @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "OTHER")'>
              <v-icon name="file-pdf"/>
            </b-button>
            <b-button
              variant="outline-secondary" class="ml-2" size="sm"
              @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
              <v-icon name="cloud-download-alt"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'VALIDATED'"
              variant="outline-success" class="ml-2" size="sm"
              :disabled="validateRemboursementDocumentInProcess"
              @click='conseillerValidateRemboursementDocument(data.item.id)'>
              <v-icon name="check-circle"/>
            </b-button>
            <b-button
              v-if="data.item.state != 'REFUSED'"
              variant="outline-danger" class="ml-2" size="sm"
              :disabled="refuseRemboursementDocumentInProcess"
              @click='conseillerRefuseRemboursementDocument(data.item.id)'>
              <v-icon name="times-circle"/>
            </b-button>
          </div>
        </template>
      </b-table>
      <pdfViewer
        v-if="pdfViewerType === 'OTHER'"
        :pdfSrc="pdfSrc"
        @close="closePdfViewer"
      />
    </div>
    <div class="row mt-2">
      <div class="col-sm-12 text-right">
        <b-button
          variant="outline-info" size="sm" pill
          @click="showCreateRemboursementDocumentModal('OTHER')">
            <v-icon name="plus" /> Ajouter un fichier
        </b-button>
      </div>
    </div>


    <!-- FRAIS COMPLEMENTAIRES -->
    <div v-if="remboursement.want_frais_deplacement">
      <h3 class="mt-4 text-info"> Frais supplémentaires</h3>

      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;">Contrats de travail avec la mention en tournée</h3>
          <em class="text-secondary">
            Il s'agit du / des contrat(s) de travail comportant la mention <u>en tournée</u>
          </em><br>

        </div>
      </div>

      <div v-if="fdContratsTravailDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="fdContratsTravailDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Contrat<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "FDCONTRATS")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'FDCONTRATS'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('FDCONTRATS')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;">Fiches de paie ou AEM de la période concernée</h3>
          <em class="text-secondary">
            Il s'agit des fiches de paie ou AEM concernant la période de la demande de frais supplémentaires
          </em><br>

        </div>
      </div>

      <div v-if="fdFichesPaieDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="fdFichesPaieDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Fiche<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "FDFICHEPAIES")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'FDFICHEPAIES'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('FDFICHEPAIES')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Bulletins de salaire / Facture</h3>
          <em class="text-secondary">
            Il s'agit des bulletins de salaire de la garde d'enfants ou des factures de la structure de garde à domicile
          </em><br>

        </div>
      </div>

      <div v-if="fdBulSalDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="fdBulSalDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Bulletin ou facture<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "FDBULSAL")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'FDBULSAL'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('FDBULSAL')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;"> Bordereau de cotisations sociales</h3>
          <em class="text-secondary">
            Il s'agit du bordereau de cotisations sociale de la période concernée
          </em><br>

        </div>
      </div>

      <div v-if="fdBordereauDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="fdBordereauDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Bordereau<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "FDCOTIS")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'FDCOTIS'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('FDCOTIS')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;">Attestation de paiement de la CAF</h3>
          <em class="text-secondary">
            Il s'agit de l'attestation de paiement de la CAF pour la période concernée
          </em><br>

        </div>
      </div>

      <div v-if="fdAttestCafDocs.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="fdAttestCafDocs"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Attestation<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "FDCAF")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'FDCAF'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('FDCAF')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;">Justificatifs des frais de transports - Montant remboursé : {{montantCalculatedPerFraisSup['TRANSPORT']}} €</h3>
          <em class="text-secondary">
            Montant et justificatifs des frais de transports
          </em><br>

        </div>
      </div>

      <div class="mt-2 frais-supplementaires">
        <div
          v-for="(frais, index) in fraisSupplementairesTransport"
          :key="`frais-sup-transport-${index}`"
          class="frais-supplementaires--item"
        >
          <div>
            <strong>Montant total de {{frais.montant}} €</strong>
          </div>
          <div
            v-if="remboursement.state !== '7MVALIDATED' && remboursement.state !== '8PAIEMENT'"
            @click="deleteFraisSupplementaire(frais)"
            class="frais-supplementaires--item--close-window"
            v-b-popover.hover.top="'Supprimer'"
          >
            <div class="frais-supplementaires--item--close-window--cross">&#x2716;</div>
          </div>
        </div>
        <div v-if="fraisSupplementairesTransport.length < 1" class="frais-supplementaires--add">
          <b-form-input
            type="number"
            step="1"
            v-model="fraisSupTransportMontant"
            placeholder="Montant total de vos frais de transports"
          />
          <b-button
            variant="outline-success" size="sm" pill
            @click="addFraisSupplementaire('TRANSPORT')"
            :disabled="$v.fraisSupTransportMontant.$invalid || remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'"
          >
            Ajouter
          </b-button>
        </div>
      </div>

      <div v-if="fdJustifTrans.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="fdJustifTrans"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Justificatif<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "FDJUSTIFTRANS")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'FDJUSTIFTRANS'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('FDJUSTIFTRANS')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-12">
          <h3 style=" margin-bottom: 0px;">Justificatifs des frais d'hébergement et de dîner</h3>
          <em class="text-secondary">
            Informations et justificatifs des frais d'hébergement et de dîner
          </em><br>

        </div>
      </div>

      <div class="mt-2 frais-supplementaires">
        <strong> Frais d'hébergements - Montant remboursé : {{montantCalculatedPerFraisSup['HEBERGEMENT']}} €</strong>
        <div
          v-for="(frais, index) in fraisSupplementairesHebergement"
          :key="`frais-sup-hebergements-${index}`"
          class="frais-supplementaires--item mt-2"
        >
          <div>
            Le
            <strong>{{$dayjs(frais.date).format('DD/MM/YYYY')}}</strong>
            durant
            <strong v-if="frais.nb_nuits > 1">{{frais.nb_nuits}} nuits</strong>
            <strong v-else>{{frais.nb_nuits}} nuit</strong>
            pour un montant de <strong>{{frais.montant}} €</strong>
          </div>
          <div
            v-if="remboursement.state !== '7MVALIDATED' && remboursement.state !== '8PAIEMENT'"
            @click="deleteFraisSupplementaire(frais)"
            class="frais-supplementaires--item--close-window"
            v-b-popover.hover.top="'Supprimer'"
          >
            <div class="frais-supplementaires--item--close-window--cross">&#x2716;</div>
          </div>
        </div>
        <div v-if="fraisSupplementairesHebergement.length < 15" class="frais-supplementaires--add mt-2">
          <b-form-input
            type="date"
            v-model="fraisSupHebergementDate"
            placeholder="Date"
          />
          <b-form-input
            type="number"
            step="1"
            v-model="fraisSupHebergementNbNuits"
            placeholder="Nombre de nuits"
          />
          <b-form-input
            type="number"
            step="1"
            v-model="fraisSupHebergementMontant"
            placeholder="Montant"
          />
          <b-button
            variant="outline-success" size="sm" pill
            @click="addFraisSupplementaire('HEBERGEMENT')"
            :disabled="$v.fraisSupHebergementDate.$invalid || $v.fraisSupHebergementNbNuits.$invalid
              || $v.fraisSupHebergementMontant.$invalid
              || remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'"
          >
            Ajouter
          </b-button>
        </div>
      </div>

      <div class="mt-2 frais-supplementaires">
        <strong class="mt-3"> Frais de repas - Montant remboursé : {{montantCalculatedPerFraisSup['REPAS']}} €</strong>
        <div
          v-for="(frais, index) in fraisSupplementairesRepas"
          :key="`frais-sup-repas-${index}`"
          class="frais-supplementaires--item mt-2"
        >
          <div>
            Le
            <strong>{{$dayjs(frais.date).format('DD/MM/YYYY')}}</strong>
            pour un montant de <strong>{{frais.montant}} €</strong>
          </div>
          <div
            v-if="remboursement.state !== '7MVALIDATED' && remboursement.state !== '8PAIEMENT'"
            @click="deleteFraisSupplementaire(frais)"
            class="frais-supplementaires--item--close-window"
            v-b-popover.hover.top="'Supprimer'"
          >
            <div class="frais-supplementaires--item--close-window--cross">&#x2716;</div>
          </div>
        </div>
        <div v-if="fraisSupplementairesRepas.length < 31" class="frais-supplementaires--add mt-2">
          <b-form-input
            type="date"
            v-model="fraisSupRepasDate"
            placeholder="Date"
          />
          <b-form-input
            type="number"
            step="1"
            v-model="fraisSupRepasMontant"
            placeholder="Montant"
          />
          <b-button
            variant="outline-success" size="sm" pill
            @click="addFraisSupplementaire('REPAS')"
            :disabled="$v.fraisSupRepasMontant.$invalid || $v.fraisSupRepasDate.$invalid
              || remboursement.state == '7MVALIDATED' || remboursement.state == '8PAIEMENT'"
          >
            Ajouter
          </b-button>
        </div>
      </div>

      <div v-if="fdJustifHeb.length === 0">
        <div class="row">
          <div class="col-sm-12 mt-2 mb-3 text-center">
            <em>Aucun document encore chargé </em>
          </div>
        </div>
      </div>

      <div v-else>
        <b-table
          responsive class="align-middle text-center mt-4 cursor-table"
          headVariant="light"
          :fields="documentsRenderFields"
          :items="fdJustifHeb"
          >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template v-slot:cell(file_name)="data">
            Justificatif<br>
            <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
          </template>

          <template v-slot:cell(state)="data">
            <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé</strong>
            <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
            <strong v-else class="text-secondary">A valider</strong>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="remboursement.state === '6MREFUSED' || remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'">
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <em v-if="remboursement.state === '6MREFUSED'" class="text-secondary"> Demande refusée par le manager. </em>
              <em v-if="remboursement.state === '7MVALIDATED' || remboursement.state === '8PAIEMENT'" class="text-secondary"> Demande validée par le manager. </em>
            </div>
            <div v-else>
              <b-button
                v-b-popover.hover.top="'Visualiser en pdf'"
                variant="outline-danger" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, true, "FDJUSTIFHEB")'>
                <v-icon name="file-pdf"/>
              </b-button>
              <b-button
                variant="outline-secondary" class="ml-2" size="sm"
                @click='conseillerGetProtectedRemboursementDocument(data.item.id, data.item.file_name, false)'>
                <v-icon name="cloud-download-alt"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'VALIDATED'"
                variant="outline-success" class="ml-2" size="sm"
                :disabled="validateRemboursementDocumentInProcess"
                @click='conseillerValidateRemboursementDocument(data.item.id)'>
                <v-icon name="check-circle"/>
              </b-button>
              <b-button
                v-if="data.item.state != 'REFUSED'"
                variant="outline-danger" class="ml-2" size="sm"
                :disabled="refuseRemboursementDocumentInProcess"
                @click='conseillerRefuseRemboursementDocument(data.item.id)'>
                <v-icon name="times-circle"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <pdfViewer
          v-if="pdfViewerType === 'FDJUSTIFHEB'"
          :pdfSrc="pdfSrc"
          @close="closePdfViewer"
        />
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right">
          <b-button
            variant="outline-info" size="sm" pill
            @click="showCreateRemboursementDocumentModal('FDJUSTIFHEB')">
              <v-icon name="plus" /> Ajouter un fichier
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'

import { validationMixin } from 'vuelidate'
import { required, integer, minLength, maxLength, decimal} from 'vuelidate/lib/validators'
import { APIConseillerConnected } from '@/api/APIConseillerConnected'
import pdfViewer from '@/components/pdf/pdfViewer'
import renderMixins from '@/mixins/renderMixins'
import { fileOperations } from '@/mixins/utilMixins'
import { file_size_validation, file_extension_validation, isInteger, isDate } from '@/validators/validators'
import gardeCaracteristique from '@/components/remboursement/gardeCaracteristique/gardeCaracteristique'
import { ccnList } from '@/variables/ccn'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiConseillerConnected = new APIConseillerConnected()

export default {
  name: 'conseillerRemboursementDocumentsProfile',
  components: {
    gardeCaracteristique,
    pdfViewer
  },
  props: {
    remboursement: Object,
    montantCalculatedPerModeGarde: {
      type: Object,
      default: null
    },
    montantCalculatedPerFraisSup: {
      type: Object,
      default: null
    },
  },
  mixins: [
    fileOperations,
    Loading,
    renderMixins,
    validationMixin,
  ],
  data: function () {
    return {

      documentsRenderFields: [
        { key: 'index', label: "#", tdClass: 'ui-helper-center'},
        { key: "file_name", label: "Document", tdClass: 'ui-helper-center'},
        { key: "state", label: "Statut", tdClass: 'ui-helper-center'},
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
      ],

      teleactualisationDocumentsRenderFields: [
        { key: 'index', label: "#", tdClass: 'ui-helper-center'},
        { key: "file_name", label: "Document", tdClass: 'ui-helper-center'},
        { key: "state", label: "Statut", tdClass: 'ui-helper-center'},
        { key: "nb_hours", label: "Nombre d'heures", tdClass: 'ui-helper-center'},
        { key: "periode_emplois", label: "Périodes d'emploi", tdClass: 'ui-helper-center'},
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
      ],

      ccnOptionsList: ccnList,

      avisPoleEmploiDocs: [],
      paieDocs: [],
      otherDocs: [],

      // SI CRECHE GAD STRUC OU PAIR
      cafPaieDocs: [],

      // CRECHE
      factMensDocs: [],

      // GAD PAJEMPLOI
      fichePaieDocs: [],
      certPajDocs: [],

      // GAD CESU
      paieEmployeDocs: [],
      bordDocs: [],

      // GAD STRUC
      structFactDocs: [],

      // Pair
      contratDocs: [],
      decUrssafDocs: [],

      // Frais deplacement
      fdContratsTravailDocs: [],
      fdFichesPaieDocs: [],
      fdBulSalDocs: [],
      fdBordereauDocs: [],
      fdAttestCafDocs: [],
      fdJustifTrans: [],
      fdJustifHeb: [],

      isRemboursementDocumentsLoading: false,

      refuseRemboursementDocumentButtonStyle: "outline-secondary",
      refuseRemboursementDocumentInProcess: false,

      validateRemboursementDocumentButtonStyle: "outline-secondary",
      validateRemboursementDocumentInProcess: false,

      modifyRemboursementDocumentInProcess: false,

      crecheNbHours: 0,
      gadPajEmploiNbHours: 0,
      gadCesuNbHours: 0,
      gadStructureNbHours: 0,
      pairNbHours: 0,

      modifyRemboursementModesGardeCaracteristiquesInProcess: false,

      createRemboursementDocumentFile: [],
      createRemboursementDocumentType: '',
      createRemboursementDocumentInProcess: false,

      // PERIODE EMPLOIS
      modifyRemboursementDocument: null,
      newPeriodeEmploiStartDate: null,
      newPeriodeEmploiEndDate: null,

      // PDF
      pdfSrc: null,
      pdfViewerType: null,

      // GARDE CARACTERISTIQUES
      newChildGardeCaracteristiqueCreche: null,
      childGardeCaractertiqueAdd: {
        'CRECHE': null
      },

      // FRAIS SUPPLEMENTAIRES
      // Transport
      fraisSupTransportMontant: null,

      // Hebergement
      fraisSupHebergementDate: null,
      fraisSupHebergementMontant: null,
      fraisSupHebergementNbNuits: null,

      // Repas
      fraisSupRepasDate: null,
      fraisSupRepasMontant: null,

    }
  },
  computed: {
    childrenSelect () {
      return this.remboursement.children.reduce((children, item) => {
        return [
          ...children,
          {
            value: item.id,
            text: `${item.first_name} ${item.last_name}`
          }
        ]
      }, [{value: null, text: ''}])
    },
    fraisSupplementairesHebergement () {
      return this.remboursement.frais_supplementaires.filter(frais => frais.type == 'HEBERGEMENT')
    },
    fraisSupplementairesRepas () {
      return this.remboursement.frais_supplementaires.filter(frais => frais.type == 'REPAS')
    },
    fraisSupplementairesTransport () {
      return this.remboursement.frais_supplementaires.filter(frais => frais.type == 'TRANSPORT')
    },
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isRemboursementDocumentsLoading || this.createRemboursementDocumentInProcess || this.modifyRemboursementDocumentInProcess || this.modifyRemboursementModesGardeCaracteristiquesInProcess) {
        return true
      }
      return false
    },

    teleActualisationChevauchageDate () {
      // On parcour tous les docs
      let periodesEmploi = []

      for (var k = 0; k < this.avisPoleEmploiDocs.length; k += 1) {
        // On parcours tous les docs après le doc précédente
        for (var l = 0; l < this.avisPoleEmploiDocs[k].periode_emplois.length; l += 1) {
          periodesEmploi.push({
            'start_date': this.$dayjs(this.avisPoleEmploiDocs[k].periode_emplois[l].start_date),
            'end_date': this.$dayjs(this.avisPoleEmploiDocs[k].periode_emplois[l].end_date)
          })
        }
      }

      const chevauchagePeriodeEmplois = []

      for (var i = 0; i < periodesEmploi.length; i += 1) {
        // On parcours tous les docs après le doc précédente
        for (var j = i + 1; j < periodesEmploi.length; j += 1) {
          // https://stackoverflow.com/questions/22784883/check-if-more-than-two-date-ranges-overlap
          if ((periodesEmploi[i].start_date <= periodesEmploi[j].start_date && periodesEmploi[j].start_date <= periodesEmploi[i].end_date) ||
              (periodesEmploi[i].start_date <= periodesEmploi[j].end_date && periodesEmploi[j].end_date <= periodesEmploi[i].end_date) ||
              (periodesEmploi[j].start_date <  periodesEmploi[i].start_date && periodesEmploi[i].end_date < periodesEmploi[j].end_date)) {
                chevauchagePeriodeEmplois.push([periodesEmploi[i], periodesEmploi[j]])
            }
        }
      }
      return chevauchagePeriodeEmplois
    }
  },
  validations: {
    crecheNbHours: {
      required,
      integer
    },
    gadPajEmploiNbHours: {
      required,
      integer
    },
    gadCesuNbHours: {
      required,
      integer
    },
    gadStructureNbHours: {
      required,
      integer
    },
    pairNbHours: {
      required,
      integer
    },

    createRemboursementDocumentFile: {
      required,
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },

    newPeriodeEmploiStartDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    newPeriodeEmploiEndDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    // FRAIS SUPPLEMENTAIRES
    fraisSupTransportMontant: {
      required,
      decimal
    },
    fraisSupHebergementDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    fraisSupHebergementNbNuits: {
      required,
      integer
    },
    fraisSupHebergementMontant: {
      required,
      decimal
    },
    fraisSupRepasDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    fraisSupRepasMontant: {
      required,
      decimal
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.conseillerGetAllRemboursementDocuments()
  },

  watch: {
    remboursement: function(newRemboursement) {
      this.crecheNbHours = newRemboursement.creche_nb_hours
      this.gadPajEmploiNbHours = newRemboursement.gad_pajemploi_nb_hours
      this.gadCesuNbHours = newRemboursement.gad_cesu_nb_hours
      this.gadStructureNbHours = newRemboursement.gad_structure_nb_hours
      this.pairNbHours = newRemboursement.pair_nb_hours
    }
  },
  methods: {

    addFraisSupplementaire(type) {
      const data = {
        remboursement: this.remboursement.id,
        type: type
      }
      if (type === 'TRANSPORT') {
        data['montant'] = this.fraisSupTransportMontant
      }
      else if (type === 'REPAS') {
        data['date'] = this.fraisSupRepasDate
        data['montant'] = this.fraisSupRepasMontant
      }
      else if (type === 'HEBERGEMENT') {
        data['date'] = this.fraisSupHebergementDate
        data['montant'] = this.fraisSupHebergementMontant
        data['nb_nuits'] = this.fraisSupHebergementNbNuits
      }
      this.remboursementLoading = true
      apiConseillerConnected.createFraisSupplementaire(this.token, data)
        .then((result) => {
          this.remboursement.frais_supplementaires.push(result.data)
          if (type === 'TRANSPORT') {
            this.fraisSupTransportMontant = null
          }
          else if (type === 'REPAS') {
            this.fraisSupRepasDate = null
            this.fraisSupRepasMontant = null
          }
          else if (type === 'HEBERGEMENT') {
            this.fraisSupHebergementDate = null
            this.fraisSupHebergementMontant = null
            this.fraisSupHebergementNbNuits = null
          }
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.remboursementLoading = false
        })
    },

    closePdfViewer () {
      this.pdfSrc = null,
      this.pdfViewerType = null
    },

    deleteFraisSupplementaire(fraisSupplementaire) {
      this.remboursementLoading = true
      apiConseillerConnected.deleteFraisSupplementaire(this.token, fraisSupplementaire.id)
        .then(() => {
          this.remboursement.frais_supplementaires = this.remboursement.frais_supplementaires.filter(function (obj) {
            return obj.id !== fraisSupplementaire.id;
          });
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.remboursementLoading = false
        })
    },

    // -------------------- ACCORD DOCUMENTS  --------------
    conseillerGetAllRemboursementDocuments () {
      this.isRemboursementDocumentsLoading = true
      this.avisPoleEmploiDocs = []
      this.paieDocs = []
      this.otherDocs = []

      this.cafPaieDocs = []

      this.factMensDocs = []

      this.fichePaieDocs = []
      this.certPajDocs = []

      this.paieEmployeDocs = []
      this.bordDocs = []

      this.structFactDocs = []

      this.contratDocs = []
      this.decUrssafDocs = []

      this.fdContratsTravailDocs = []
      this.fdFichesPaieDocs = []
      this.fdBulSalDocs = []
      this.fdBordereauDocs = []
      this.fdAttestCafDocs = []
      this.fdJustifTrans = []
      this.fdJustifHeb = []

      apiConseillerConnected.getAllRemboursementsDocuments(this.token, this.$route.params.remboursement_id)
        .then((result) => {
          var documentsLength = result.data.length;
          for (var i = 0; i < documentsLength; i++) {
            if (result.data[i]['type'] === 'TELEACTUALISATION') {
              this.avisPoleEmploiDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'PAIE') {
              this.paieDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'OTHER') {
              this.otherDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'CAFPAIE') {
              this.cafPaieDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FACTMENS') {
              this.factMensDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FICHEPAIE') {
              this.fichePaieDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'CERTPAJ') {
              this.certPajDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'PAIEEMPLOYE') {
              this.paieEmployeDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'BORD') {
              this.bordDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'STRUCTFACT') {
              this.structFactDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'CONTRAT') {
              this.contratDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'DECURSSAF') {
              this.decUrssafDocs.push(result.data[i])
            }

            else if (result.data[i]['type'] === 'FDCONTRATS') {
              this.fdContratsTravailDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDFICHEPAIES') {
              this.fdFichesPaieDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDBULSAL') {
              this.fdBulSalDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDCOTIS') {
              this.fdBordereauDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDCAF') {
              this.fdAttestCafDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDJUSTIFTRANS') {
              this.fdJustifTrans.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'FDJUSTIFHEB') {
              this.fdJustifHeb.push(result.data[i])
            }
          }
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('ConseillerRemboursementProfile conseillerGetAllRemboursementDocuments API Error : ' + String(error))
        })
        .finally(() => {
          this.isRemboursementDocumentsLoading = false
        })
    },

    conseillerGetProtectedRemboursementDocument (document_id, document_name, as_pdf, pdf_type) {
      if (pdf_type) {
        this.pdfViewerType = pdf_type
      }
      if (this.pdfSrc) {
        window.URL.revokeObjectURL(this.pdfSrc)
      }
      apiConseillerConnected.getRemboursementProtectedDocument(this.token, document_id)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        if (as_pdf) {
          if (document_name.split('.').pop() !== "pdf"){
            var img = new Image()
            img.src = url
            var doc = new jsPDF()
            doc.addImage(img, 'JPEG', 0, 0, 200, 0)
            let blobPdf = new Blob([doc.output('blob')], {type: 'application/pdf'})
            let blobUrl = window.URL.createObjectURL(blobPdf)
            this.pdfSrc = blobUrl
          }
          else {
            this.pdfSrc = url
          }
          this.$refs.pdfViewer.show()
        }
        else {
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', document_name)
          document.body.appendChild(link)
          link.click()
        }
      }, () => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
    },



    conseillerValidateRemboursementDocument (doc_id) {
      this.validateRemboursementDocumentButtonStyle = "secondary"
      this.validateRemboursementDocumentInProcess = true
      apiConseillerConnected.validateRemboursementDocument(this.token, doc_id)
      .then(() => {
        this.conseillerGetAllRemboursementDocuments()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerRemboursementProfile validateRemboursementDocument API Error : ' + String(error))
      })
      .finally(() => {
        this.validateRemboursementDocumentButtonStyle = "outline-success"
        this.validateRemboursementDocumentInProcess = false
      })
    },

    conseillerRefuseRemboursementDocument (doc_id) {
      this.refuseRemboursementDocumentButtonStyle = "secondary"
      this.refuseRemboursementDocumentInProcess = true
      apiConseillerConnected.refuseRemboursementDocument(this.token, doc_id)
      .then(() => {
        this.conseillerGetAllRemboursementDocuments()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('ConseillerRemboursementProfile refuseRemboursementDocument API Error : ' + String(error))
      })
      .finally(() => {
        this.refuseRemboursementDocumentButtonStyle = "outline-danger"
        this.refuseRemboursementDocumentInProcess = false
      })
    },

    isStringInteger (value) {
      return isInteger(value)
    },

    setNbHoursCCNRemboursementDocument (remboursement_document_id, nb_hours, ccn) {
      if (!this.isStringInteger(nb_hours) || this.modifyRemboursementDocumentInProcess) {
        return null
      }
      this.modifyRemboursementDocumentInProcess = true
      apiConseillerConnected.setNbHoursCCNRemboursementDocument(
        this.token, remboursement_document_id, nb_hours, ccn)
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.modifyRemboursementDocumentInProcess = false
        })
    },


    modifyRemboursementModesGardeNbHours () {
      this.modifyRemboursementModesGardeCaracteristiquesInProcess = true
      apiConseillerConnected.modifyRemboursementModesGardeNbHoursFile(
        this.token, this.$route.params.remboursement_id, this.crecheNbHours, this.gadPajEmploiNbHours, this.gadCesuNbHours, this.gadStructureNbHours, this.pairNbHours)
        .then(() => {
          this.$emit('actualizeRemboursement')
          //this.conseillerGetAllRemboursementDocuments()
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.modifyRemboursementModesGardeCaracteristiquesInProcess = false
        })

    },


    showCreateRemboursementDocumentModal (createRemboursementDocumentType ) {
      this.createRemboursementDocumentType = createRemboursementDocumentType
      this.$refs.createRemboursementDocumentModal.show()
    },

    createRemboursementDocument () {
      this.createRemboursementDocumentInProcess = true
      apiConseillerConnected.createRemboursementDocument(
        this.token, this.$route.params.remboursement_id, this.createRemboursementDocumentType, this.createRemboursementDocumentFile)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$refs.remboursementDocumentUploadedModal.show()
          this.createRemboursementDocumentFile = []
          this.createRemboursementDocumentType = ''
          this.conseillerGetAllRemboursementDocuments()
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('conseillerRemboursementDocumentsProfile createRemboursementDocument API Error : ' + String(error))
        })
        .finally(() => {
          this.$refs.createRemboursementDocumentModal.hide()
          this.createRemboursementDocumentInProcess = false
        })
    },

    // ---- PERIODE EMPLOI

    showAddPeriodeEmploiModal (remboursement_document) {
      this.modifyRemboursementDocument = remboursement_document
      this.$refs.addPeriodeEmploiModal.show()
    },

    addPeriodeEmploi() {
      this.modifyRemboursementDocumentInProcess = true
      apiConseillerConnected.createPeriodesEmploi(
        this.token, this.modifyRemboursementDocument.id,
        [
          {
            'start_date': this.newPeriodeEmploiStartDate,
            'end_date': this.newPeriodeEmploiEndDate,
          }
        ]
      )
      .then((response) => {
        this.modifyRemboursementDocument.periode_emplois.push(response.data[0])
        this.newPeriodeEmploiStartDate = null,
        this.newPeriodeEmploiEndDate = null
        this.$refs.addPeriodeEmploiModal.hide()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('conseillerRemboursementDocumentsProfile addPeriodeEmploi API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyRemboursementDocumentInProcess = false
      })
    },

    deletePeriodeEmploi (item, index, periode_emploi) {
      this.modifyRemboursementDocumentInProcess = true
      apiConseillerConnected.deletePeriodeEmploi(this.token, periode_emploi.id)
        .then(() => {
          item.periode_emplois.splice(index, 1);
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.modifyRemboursementDocumentInProcess = false
        })
    },

    // ------- GARDE CARACTERISTIQUES --------
    addNewGardeCaracteristiquesLine(type) {
      this.childGardeCaractertiqueAdd[type] = this.remboursement.children.find((item) => item.id == this.newChildGardeCaracteristiqueCreche)
    },

    filterGardeCaracteristiques(type, childId) {
      return this.remboursement.garde_caracteristiques.filter((item) => item.type === type && item.child === childId)[0]
    },

    filterChildWithoutGardeCaracteristiques(type) {
      const children_ids = this.remboursement.garde_caracteristiques.filter((item) => item.type === type).map((item) => item.child)
      return this.remboursement.children.filter((item) => !children_ids.includes(item.id))
    },

    createGardeCaracteristiques (data) {
      this.modifyRemboursementModesGardeCaracteristiquesInProcess = true
      apiConseillerConnected.createGardeCaracteristiques(
        this.token, data
      )
      .then((response) => {
        this.remboursement.garde_caracteristiques.push(response.data)
        this.childGardeCaractertiqueAdd[response.data.type] = null
        this.newChildGardeCaracteristiqueCreche = null
        this.$bvToast.toast('Les informations ont bien été sauvegardées', {
          title: 'Informations sauvegardées',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('conseillerRemboursementDocumentsProfile createGardeCaracteristiques API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyRemboursementModesGardeCaracteristiquesInProcess = false
      })
    },

    modifyGardeCaracteristiques (data) {
      this.modifyRemboursementModesGardeCaracteristiquesInProcess = true
      apiConseillerConnected.modifyGardeCaracteristiques(
        this.token, data.id, data
      )
      .then(() => {
        this.$bvToast.toast('Les informations ont bien été sauvegardées', {
          title: 'Informations sauvegardées',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('conseillerRemboursementDocumentsProfile createGardeCaracteristiques API Error : ' + String(error))
      })
      .finally(() => {
        this.modifyRemboursementModesGardeCaracteristiquesInProcess = false
      })
    }

  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
