<template>
  <div class="gardeCaracteristique">

    <b-form>
      <div
        v-if="!gardeCaracteristique.child_not_concerned"
        class="row"
      >
        <div class="col-12 col-md">
          <b-form-group
            id="nb_hours_worked_months-group"
            label-for="nb_hours_worked_months-input"
          >
            <template v-slot:label>
              <small> Nombre d'heures travaillées <br>sur le mois </small>
            </template>
            <b-form-input
              :disabled="disabled"
              size="sm"
              id="nb_hours_worked_months-input"
              v-model="gardeCaracteristique.nb_hours_worked_months"
              type="number"
              step="1"
              placeholder="Nombre d'heures"
              @input="$v.gardeCaracteristique.nb_hours_worked_months.$touch()"
              :state="$v.gardeCaracteristique.nb_hours_worked_months.$dirty ? !$v.gardeCaracteristique.nb_hours_worked_months.$error : null"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-1é col-md">
          <b-form-group
            id="nb_hours_garde-group"
            label-for="nb_hours_garde-input"
          >
            <template v-slot:label>
              <small> Nombre d'heures de garde <br>sur le mois </small>
            </template>
            <b-form-input
              :disabled="disabled"
              size="sm"
              id="nb_hours_garde-input"
              v-model="gardeCaracteristique.nb_hours_garde"
              type="number"
              step="1"
              placeholder="Nombre d'heures"
              @input="$v.gardeCaracteristique.nb_hours_garde.$touch()"
              :state="$v.gardeCaracteristique.nb_hours_garde.$dirty ? !$v.gardeCaracteristique.nb_hours_garde.$error : null"
            ></b-form-input>
          </b-form-group>
        </div>
        <div
          v-if="type === 'CRECHE'"
          class="col-12 col-md"
        >
          <b-form-group
            id="taux_horaire-group"
            label-for="taux_horaire-input"
          >
            <template v-slot:label>
              <small>Taux horaire en €<br>
                (si crèche)
              </small>
            </template>
            <b-form-input
              :disabled="disabled"
              size="sm"
              id="taux_horaire-input"
              v-model="gardeCaracteristique.taux_horaire"
              type="number"
              step="1"
              placeholder="Taux horaire"
              @input="$v.gardeCaracteristique.taux_horaire.$touch()"
              :state="$v.gardeCaracteristique.taux_horaire.$dirty ? !$v.gardeCaracteristique.taux_horaire.$error : null"
            ></b-form-input>
          </b-form-group>
        </div>
        <div
          v-if="type === 'CRECHE' || type === 'GAD_STRUCTURE'"
          class="col-12 col-md"
        >
          <b-form-group
            id="cout_mensuel-group"
            label-for="cout_mensuel-input"
          >
            <template v-slot:label>
              <small>Coût mensuel<br>
                <span v-if="type === 'CRECHE'">(si périscolaire / garderie)</span>
                <span v-else>de la structure</span>
              </small>
            </template>
            <b-form-input
              :disabled="disabled"
              size="sm"
              id="cout_mensuel-input"
              v-model="gardeCaracteristique.cout_mensuel"
              type="number"
              step="1"
              placeholder="Taux (en €)"
              @input="$v.gardeCaracteristique.cout_mensuel.$touch()"
              :state="$v.gardeCaracteristique.cout_mensuel.$dirty ? !$v.gardeCaracteristique.cout_mensuel.$error : null"
            ></b-form-input>
          </b-form-group>
        </div>
        <div
          v-if="type === 'GAD_PAJEMPLOI' || type === 'GAD_CESU' || type === 'PAIR'"
          class="col-12 col-md"
        >
          <b-form-group
            id="salaire_net-group"
            label-for="salaire_net-input"
          >
            <template v-slot:label>
              <small> Salaire net versé <br>en € </small>
            </template>
            <b-form-input
              :disabled="disabled"
              size="sm"
              id="salaire_net-input"
              v-model="gardeCaracteristique.salaire_net"
              type="number"
              step="1"
              placeholder="Salaire net (en €)"
              @input="$v.gardeCaracteristique.salaire_net.$touch()"
              :state="$v.gardeCaracteristique.salaire_net.$dirty ? !$v.gardeCaracteristique.salaire_net.$error : null"
            ></b-form-input>
          </b-form-group>
        </div>
        <div
          v-if="type === 'GAD_PAJEMPLOI' || type === 'GAD_CESU' || type === 'PAIR'"
          class="col-12 col-md"
        >
          <b-form-group
            id="reste_a_charge_cotisations-group"
            label-for="reste_a_charge_cotisations-input"
          >
            <template v-slot:label>
              <small v-if="type === 'GAD_CESU'"> 
                Total des cotisations <br>sociales 
              </small>
              <small v-else> 
                Reste à charge<br>des cotisations sociales
              </small>

            </template>
            <b-form-input
              :disabled="disabled"
              size="sm"
              id="reste_a_charge_cotisations-input"
              v-model="gardeCaracteristique.reste_a_charge_cotisations"
              type="number"
              step="1"
              placeholder="Reste à charge (en €)"
              @input="$v.gardeCaracteristique.reste_a_charge_cotisations.$touch()"
              :state="$v.gardeCaracteristique.reste_a_charge_cotisations.$dirty ? !$v.gardeCaracteristique.reste_a_charge_cotisations.$error : null"
            ></b-form-input>
          </b-form-group>
        </div>
        <div
          v-if="type === 'GAD_STRUCTURE' || type === 'GAD_PAJEMPLOI' || type === 'PAIR'"
          class="col-12 col-md"
        >
          <b-form-group
            id="cmg_mois-group"
            label-for="cmg_mois-input"
          >
            <template v-slot:label>
              <small> Complément mode garde <br>perçu sur le mois </small>
            </template>
            <b-form-input
              :disabled="disabled"
              size="sm"
              id="cmg_mois-input"
              v-model="gardeCaracteristique.cmg_mois"
              type="number"
              step="1"
              placeholder="Complément perçu (en €)"
              @input="$v.gardeCaracteristique.cmg_mois.$touch()"
              :state="$v.gardeCaracteristique.cmg_mois.$dirty ? !$v.gardeCaracteristique.cmg_mois.$error : null"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-1 text-center align-self-end">
          <b-button
            @click="save"
            :disabled="$v.gardeCaracteristique.$invalid || disabled"
            variant="outline-info"
            size="sm"
            pill
            block
            style="margin-bottom: 1rem;"
          >
           Sauver
         </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-form-checkbox
            :disabled="disabled"
            :id="`child_not_concerned-${child.id}-${type}`"
            v-model="gardeCaracteristique.child_not_concerned"
            :name="`child_not_concerned-${child.id}-${type}`"
            :value="true"
            :unchecked-value="false"
            @change="modifyChildConcerned($event)"
          >
            {{child.first_name}} {{child.last_name}} n'est pas concerné.e par ce mode de garde
          </b-form-checkbox>
        </div>
      </div>
    </b-form>
</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue, requiredIf, decimal } from 'vuelidate/lib/validators'


export default {
  name: 'gardeCaracteristique',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isExistingGarde: {
      type: Boolean,
      default: false
    },
    type: String,
    gardeCaracteristique: {
      type: Object,
      default: function() {
        return {
          child_not_concerned: false,
          nb_hours_worked_months: null,
          nb_hours_garde: null,
          taux_horaire: null,
          cout_mensuel: null,
          salaire_net: null,
          reste_a_charge_cotisations: null,
          cmg_mois: null
        }
      }
    },
    child: {
      type: Object,
      default: function() {
        return {
          id: null,
          first_name: null,
          last_name: null
        }
      }
    },
    remboursementId: Number
  },
  mixins: [
    validationMixin
  ],
  validations: {
    gardeCaracteristique: {
      nb_hours_worked_months: {
        required,
        decimal,
        minValue: minValue(0)
      },
      nb_hours_garde: {
        required,
        decimal,
        minValue: minValue(0)
      },
      taux_horaire: {
        required: requiredIf(function () {
          return this.type === 'CRECHE' && (!this.gardeCaracteristique.cout_mensuel || this.gardeCaracteristique.cout_mensuel === '')
        }),
      },
      cout_mensuel: {
        required: requiredIf(function () {
          return this.type === 'GAD_STRUCTURE' || (this.type === 'CRECHE' && (!this.gardeCaracteristique.taux_horaire || this.gardeCaracteristique.taux_horaire === ''))
        }),
      },
      salaire_net: {
        required: requiredIf(function () {
          return this.type === 'GAD_PAJEMPLOI' || this.type === 'GAD_CESU' || this.type === 'PAIR'
        }),
        decimal,
        minValue: minValue(0)
      },
      reste_a_charge_cotisations: {
        required: requiredIf(function () {
          return this.type === 'GAD_PAJEMPLOI' || this.type === 'GAD_CESU' || this.type === 'PAIR'
        }),
        decimal,
        minValue: minValue(0)
      },
      cmg_mois: {
        required: requiredIf(function () {
          return this.type === 'GAD_STRUCTURE' || this.type === 'GAD_PAJEMPLOI' || this.type === 'PAIR'
        }),
        decimal,
        minValue: minValue(0)
      },
    }

  },
  methods: {
    save () {
      if (this.isExistingGarde) {
        const data = {}
        for (const [key, value] of Object.entries(this.gardeCaracteristique)) {
          if (value === '') {
            data[key] = null
          }
          else {
            data[key] = value
          }
        }
        this.$emit('modify-garde-information', data)
      }
      else {
        const data = {
          'remboursement': this.remboursementId,
          'child': this.child.id,
          'type': this.type,
        }
        for (const [key, value] of Object.entries(this.gardeCaracteristique)) {
          if (value === '') {
            data[key] = null
          }
          else {
            data[key] = value
          }
        }
        this.$emit('create-garde-information', data)
      }
    },

    modifyChildConcerned($event) {
      if (this.isExistingGarde) {
        this.$emit('modify-garde-information', {'id': this.gardeCaracteristique.id, 'child_not_concerned': $event})
      }
      else {
        this.$emit(
          'create-garde-information',
          {
            'remboursement': this.remboursementId,
            'child': this.child.id,
            'type': this.type,
            'child_not_concerned': $event
          }
        )
      }
    }
  }

}
</script>
