export const ccnList = [
    { value: '1PRODAUDIO', text: 'Production audiovisuelle'},
    { value: '2PRODCINE', text: 'Production cinématographique'},
    { value: '3EDCINE', text: 'Edition phonographique'},
    { value: '4PRESTEC', text: "Prestations techniques au service de la création et de l'évènement"},
    { value: '5RADIO', text: 'Radiodiffusion'},
    { value: '6SPECSUB', text: 'Spectacle vivant subventionné'},
    { value: '7SPECPRIV', text: 'Spectacle vivant privé'},
    { value: '8LOIS', text: "Espaces des loisirs, d'attractions et culturels"},
    { value: '9TELE', text: "Télédiffusion"},
    { value: '10ANIM', text: "Production de films d'animation"},
    { value: '11GUSO', text: "Guso"},
]